<template>
    <div class="main-container">
        <h1 class="title-solo">About us</h1>
        <div class="about-container">
            <div class="about-item" v-for="(item, idx) in about" :key="idx">
                <div class="about-content">
                    <a :href="item.url" target="_blank">
                        <img :src="item.logo" :alt="item.logoAlt" class="about-person" />
                    </a>
                    <!-- <h2>{{ item.name }}</h2> -->
                    <p v-html="item.text"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'AboutUs',
    data() {
        return {
            about : [
                {
                    logo : require('@/assets/about/logo_percall.jpg'),
                    logoAlt : 'logo PERCALL',
                    url : 'http://www.percall.fr/',
                    name : 'PERCALL',
                    text : 'Percall has been positioned as a major player in the PLM and IoT markets since the year 2000. We are present in 7 countries – France, Germany, Poland, UK, Morocco, Spain, and Italy – with over 400 skilled employees. We perform consulting, training, and software development services in support of major technical programs and initiatives around digital transformation primarily in the Aerospace & Defense, Energy & Utilities and Luxury Goods markets, putting our expertise at the service of our customers.'
                },
                {
                    logo : require('@/assets/about/ptc-logo.png'),
                    logoAlt : 'logo PTC',
                    url : 'https://www.ptc.com/en/',
                    name : 'PTC',
                    text : 'PTC enables global manufacturers to realize double-digit impact with software solutions that enable them to accelerate product and service innovation, improve operational efficiency, and increase workforce productivity. In combination with an extensive partner network, PTC provides customers flexibility in how its technology can be deployed to drive digital transformation – on premises, in the cloud, or via its pure SaaS platform. At PTC, we don’t just imagine a better world, we enable it.'
                },
                {
                    logo : require('@/assets/about/logo-s3d.png'),
                    logoAlt : 'logo SYNTHES3D',
                    url : 'https://www.synthes3d.com/en/',
                    name : 'SYNTHES\'3D',
                    text : 'Created in 2007, SYNTHES’3D is a digital studio specialized in Virtual Reality, in realtime 3D and in contents for VR & AR applications and solutions. Focused on Industry and Medical sectors, SYNTHES’3D is based in France (Aix-en-Provence & Angers) and in the USA. The team is composed of 15 talented designers.'
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    .main-container {
        background-image: url('./../assets/bg-about.png');
        background-repeat: no-repeat;
        background-position: center left;
    }
    
    .about-container {
        width: $box;
        margin: 0 auto;
        display: flex;
        max-width: 90%;
        justify-content: center;
        flex-wrap: wrap;
    }

    .about-logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height:120px;
    }

    .about-item {
        width:33%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .about-content {
        width:337px;
        border:1px solid $main-color;
        border-radius:10px;
        margin-top:20px;
        background: #fff;
    }

    .about-content a {
        display: flex;
        width:100%;
        margin-top:20px;
        justify-content: center;
        margin-bottom:20px;
    }

    .about-person {
        border-radius:10px;
    }

    .about-content h2 {
        text-align: center;
        font-size:22px;
        font-weight:700;
        text-align: center;
        margin:20px;
        padding: 0;
        text-transform: capitalize;
    }

    .about-content p {
        color:$secondary-color;
        text-align: justify;
        margin:0 20px 20px 20px;
        line-height: 1.5em;
        height:310px;
    }

    @media screen and (max-width:1200px) {
        .about-container {
            flex-direction: column;
            align-items: center;
        }

        .about-logo-container {
            margin-top:40px;
        }

        .about-item:nth-child(1) .about-logo-container {
            margin-top:0;
        }
    }
</style>